
export interface EnvironType {

    // Env
    env: 'dev' | 'stage' | 'prod'
    isDev: boolean
    isStage: boolean

    // Watermark
    version: string
    revision: string
    buildTime: string

    // Other
    linkDocumentation?: string
    linkContacts?: string

}

const env = process.env.REACT_APP_ENV as any ?? 'dev'

export const Environ: EnvironType = {
    env,
    isDev: env === 'dev',
    isStage: env === 'stage',
    version: process.env.REACT_APP_VERSION ?? '?',
    revision: process.env.REACT_APP_REVISION ?? '?',
    buildTime: process.env.REACT_APP_BUILD_TIME ?? '?',
    linkDocumentation: process.env.REACT_APP_DOCUMENTATION,
    linkContacts: process.env.REACT_APP_CONTACTS,
}
