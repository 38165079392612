/**
 * Borut Svara
 * © 2022 all rights reserved
 *
 * @author     Borut Svara <b@aindo.com>
 * @date       2022-10-13
 *
 * The ultimate solution for skeleton rendering in React
 *
 * Usage:
 *
 *      Convert currently used html elements by adding `Skeleton.` in front, like
 *
 *      <div/>  -->  <Skeleton.div/>
 *
 *      Then either apply loading directly to the element or nest it within a
 *      <Skeleton.Provider> and go deeper.
 *
 * todo : describe
 *
 */
import { createContext, createElement, DetailedHTMLFactory, DOMElement, ReactHTML, ReactNode, useContext } from 'react';
import classNames from 'classnames';
import './skeleton.scss';

export interface SkeletonContext {
    loading?: boolean;
    skeletonReserve?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
    skeletonClass?: string;
    nonSkeletonClass?: string;
}

export interface SkeletonProviderProps extends SkeletonContext {
    children: ReactNode;
    inherit?: boolean;
}

export interface SkeletonProps extends SkeletonContext {
    ignoreContext?: boolean;
    hide?: boolean;
}

// --- Context ---

const Context = createContext<SkeletonContext | undefined>(undefined);

export function useSkeleton(defaults?: SkeletonContext): SkeletonContext {
    const context = useContext(Context);
    return (context ? { ...defaults, ...context } : defaults) ?? {};
}

function Provider({ children, inherit, ...props }: SkeletonProviderProps) {
    const inherited = useSkeleton(props);
    return <Context.Provider value={inherit ? inherited : props} children={children} />
}

// --- Utils ---

type Props<F extends DetailedHTMLFactory<any, any>> = F extends DetailedHTMLFactory<infer P, any> ? P : any;
type Element<F extends DetailedHTMLFactory<any, any>> = F extends DetailedHTMLFactory<any, infer E> ? E : any;
type SkeletizedComponent<E extends keyof ReactHTML>
    = ((props: Props<ReactHTML[E]> & SkeletonProps) => DOMElement<Props<ReactHTML[E]>, Element<ReactHTML[E]>>);

function skeletize<E extends keyof ReactHTML>(element: E): SkeletizedComponent<E> {
    return (props) => {

        // Propagate parent context
        const context = useContext(Context);
        if (context && !props.ignoreContext) props = {
            ...props, ...context
        };

        // Unpack props
        const {
            loading, skeletonReserve, skeletonClass, nonSkeletonClass, ignoreContext, hide,
            className, children, ...rest
        } = props;

        // animate-pulse bg-gray-300 text-transparent rounded-md
        return createElement(element, {
            className: classNames(
                className,
                hide && loading ? 'skeleton-hidden' : '',
                !hide && loading ? {
                    [`skeleton`]: true,
                    [`skeleton-${skeletonReserve}`]: !!skeletonReserve,
                    [skeletonClass ?? 'bg-gray-300 rounded-md']: true,
                } : {},
                !hide && !loading ? nonSkeletonClass : undefined,
            ),
            // Hack to display empty text elements
            children: children ?? (skeletonReserve ? <>&nbsp;</> : undefined),
            ...rest
        }) as any;

    }
}

// --- Components ---

export interface HideSkeletonProps extends SkeletonContext {
    children: JSX.Element
}

export function HideSkeleton({ children, ...def }: HideSkeletonProps) {
    const ctx = useSkeleton(def)
    return ctx.loading ? <></> : children
}

export interface CmpSkeletonProps extends SkeletonContext {
    children: (context: SkeletonContext) => JSX.Element
}

export function Skeleton({ children, ...def }: CmpSkeletonProps) {
    const ctx = useSkeleton(def);
    return children(ctx);
}

// todo : if we list here all HTML elements can this compilation be tree-shacked ??
Skeleton.Provider = Provider
Skeleton.Hide = HideSkeleton
Skeleton.div = skeletize('div')
Skeleton.p = skeletize('p')
Skeleton.span = skeletize('span')
Skeleton.h1 = skeletize('h1')
Skeleton.h2 = skeletize('h2')
Skeleton.h3 = skeletize('h3')
Skeleton.h4 = skeletize('h4')
Skeleton.h5 = skeletize('h5')
Skeleton.h6 = skeletize('h6')
Skeleton.small = skeletize('small')
