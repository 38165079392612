import React, { ReactNode } from 'react';
import { ProgressBar } from '../common/ProgressBar';

export function Suspense({ children }: { children?: ReactNode }) {
    const fallback = (
        <div>
            <ProgressBar className='w-full h-1' indeterminate />
        </div>
    )
    return (
        <React.Suspense
            fallback={fallback}
            children={children}
        />
    )
}

