import { ReactNode, useEffect } from 'react';
import { AuthRenewRequest, AuthRequest } from '../../../core/models/apiAuth';
import { AUTH_URL, AuthApi } from '../../../core/network/AuthApi';
import axios, { AxiosError } from 'axios';
import { MeApi } from '../../../core/network/MeApi';
import { useQueryClient } from 'react-query';
import { AuthProvider, useAuth } from '@reactit/auth';


// todo : add storage switching functionality


// >> Axios link

// todo : deprecate
const DEV_JWT = process.env.REACT_APP_DEV_JWT ?? undefined

if (DEV_JWT) {
    console.warn(`DEVELOPMENT AUTH TOKEN PRESENT`)
}

function updateAxiosToken(token: string | undefined) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

// << Axios link

export function AppAuthProvider({ children }: { children: ReactNode }) {

    const qc = useQueryClient()

    return (
        <AuthProvider

            devToken={DEV_JWT}
            devSignedIn={true}

            doSignIn={async (ctx, credentials?: AuthRequest) => {
                console.log(`[auth] doSignIn`)

                if (!credentials) throw new Error(`Auth credentials expected`)

                const res = await AuthApi.login(credentials)
                updateAxiosToken(res.authToken) // needed by MeApi
                // todo : this is not the best approach to have this query here
                const meRes = await MeApi.get()

                return {
                    token: res.authToken,
                    tokenExpiration: res.authExpiresIn,
                    renew: res.renewToken,
                    renewExpiration: res.renewExpiresIn,
                    user: meRes,
                }
            }}

            doRenew={async (ctx, request?: Pick<AuthRenewRequest, 'scopeId'>) => {
                console.log(`[auth] doRenew`)

                const res = await AuthApi.renew({ ...request, token: ctx.renew?.token! })
                return {
                    token: res.authToken,
                    tokenExpiration: res.authExpiresIn,
                }
            }}

            doSignOut={async (ctx) => {
                console.log(`[auth] doSignOut`)

                // Clear TanStack to evict all sensible data from the screen
                qc.clear()
                qc.removeQueries()

                // Expire session on API
                await AuthApi.logout()
            }}

            onTokenChange={async (ctx, token) => {
                updateAxiosToken(token)
            }}

            children={children}

        />
    )

}

export function useAppAuthInterceptor() {

    const { renewTokenAsync, signOutAsync } = useAuth()

    useEffect(() => {

        async function onReject(error: AxiosError) {

            // Detect expired token
            if (
                !error.config.params?.repeating // prevent infinite loops
                && error.response?.status === 401
                && !error?.config?.url?.startsWith(AUTH_URL)
            ) {
                error.config.params = { ...(error.config.params || {}), repeating: true }

                try {
                    await renewTokenAsync();
                    console.log('[auth] AUTO RENEW SUCCESS - REPEATING QUERY')
                    return axios.request(error.config)

                } catch (e) {
                    console.log('[auth] AUTO RENEW FAILED - LOGGING OUT')
                    await signOutAsync()
                }

            }

            return Promise.reject(error)
        }

        const ref = axios.interceptors.response.use(undefined, onReject)
        return () => axios.interceptors.response.eject(ref)

    }, [])

}
