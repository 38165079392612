import React, { createContext, MouseEventHandler, ReactNode, useContext, useState } from 'react'
import { Logo } from './Logo'
import { Levels } from '../utils/levels'
import { NodeOrComponent, renderIconFromNodeOrComponent } from '../utils/render';
import { To } from 'history';
import { NavLink, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { Suspense } from './Suspense';
import NoovleIco from '../../../assets/logos/noovle_ico';

export interface NavContextType {
    expanded: boolean
    setExpanded: (expanded: boolean) => void
}

export const NavContext = createContext<NavContextType | undefined>(undefined)

export interface NavbarProps {
    onLogoClick?: () => any
    children: ReactNode
}

export function Navbar(props: NavbarProps) {
    const { children, onLogoClick } = props

    const [expanded, setExpanded] = useState(false)

    return (
        <div>
            <div className={expanded ? 'pl-64' : 'pl-18'}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </div>
            <div className={`relative ${Levels.OVER_BASE}`}>
                <div className={classNames(
                    `fixed top-0 bottom-0 flex flex-col bg-aindo-900`,
                    expanded ? 'w-64' : '',
                )}>
                    <div className={classNames('py-4 h-18 flex items-center justify-center', expanded ? 'px-7' : '')}>
                        <NoovleIco className='w-8 h-8' />
                    </div>

                    <div className='grow flex flex-col px-4 py-6 space-y-2'>
                        <NavContext.Provider value={{ expanded: expanded, setExpanded }}>{children}</NavContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export interface NavLinkProps {
    icon: NodeOrComponent
    text?: string
    link?: To
    exact?: boolean
    onClick?: MouseEventHandler<any> // called only when link is undefined
    isNew?: boolean // flag new routes
    isBeta?: boolean // flag beta routes
    isDev?: boolean // flag development only routes
    isAttention?: boolean // flag to display red dot
}

export interface NavbarActionProps extends NavLinkProps {
    rawLink?: boolean
    target?: string
}

export function NavbarAction(
    {
        icon,
        text,
        link,
        exact = false,
        onClick,
        rawLink,
        target,
        isAttention,
    }: NavbarActionProps
) {
    // todo isNew, isBeta, isDev ?
    const isString = typeof link === 'string'
    const expanded = useContext(NavContext)?.expanded || false;

    const style = ({isActive}: any) => classNames(
    'flex justify-center rounded-lg space-x-3 cursor-pointer relative',
        isString && isActive ? 'text-aindo-500 bg-aindo-400/30' : 'text-aindo-300',
        expanded ? 'py-2 px-3' : 'p-2',
    )

    const content = <>
        {renderIconFromNodeOrComponent(icon)}
        {expanded && <span className='flex-1'>{text}</span>}
        {isAttention && <span className='absolute w-2 h-2 top-1 right-1 rounded-full bg-rose-500' />}
    </>

    if (!link) {
        return (
            <div className={style({ isActive: false })} onClick={onClick}>
                {content}
            </div>
        )
    } else if (isString && rawLink) {
        return (
            <a className={style({ isActive: false })} href={link} target={target}>
                {content}
            </a>
        )
    } else {
        return (
            <NavLink to={link} end={exact} className={style}>
                {content}
            </NavLink>
        )
    }
}
