import React from 'react';

export default function AindoByIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 130"
            {...props}
        >
            <path d="m.602 95.158 22.285 12.251a.751.751 0 0 0 1.113-.658v-3.486a1.074 1.074 0 0 0-.557-.941L9.296 94.546l9.975-5.484v6.803a1.074 1.074 0 0 0 .557.94l2.78 1.53A.939.939 0 0 0 24 97.511V82.34a.751.751 0 0 0-1.113-.659L.602 93.934a.698.698 0 0 0 0 1.224zM.794 79.043h22.412A.794.794 0 0 0 24 78.25v-3.187a.794.794 0 0 0-.794-.794H.794a.794.794 0 0 0-.794.794v3.187a.794.794 0 0 0 .794.793ZM.92 70.635h22.286a.794.794 0 0 0 .794-.794v-3.064a.794.794 0 0 0-.794-.793H9.744l14.022-14.657a.814.814 0 0 0-.588-1.376H.794a.794.794 0 0 0-.794.794v3.052a.794.794 0 0 0 .794.794H14.36L.331 69.26a.814.814 0 0 0 .588 1.376zM.794 46.341h22.412a.794.794 0 0 0 .794-.793v-6.811c0-7.167-5.24-12.576-12-12.576S0 31.57 0 38.737v6.81a.794.794 0 0 0 .794.794Zm3.835-4.64V38.45c0-4.537 3.193-7.582 7.372-7.582s7.371 3.045 7.371 7.582V41.7zM0 12c0 6.677 5.256 12.001 12 12.001s12-5.323 12-12C24 5.356 18.744 0 12 0S0 5.356 0 12zm4.63 0c0-4.11 3.229-7.164 7.37-7.164 4.14 0 7.368 3.053 7.37 7.165 0 4.14-3.23 7.194-7.37 7.194-4.141 0-7.37-3.055-7.37-7.194z" />
            <path
                d="M11.602 130H0v-6.199q0-2.403.862-3.547.845-1.144 2.171-1.144.879 0 1.575.498.68.48 1.077 1.409.398.928.398 2.287l-.895-.332q0-1.359.381-2.337.381-.994 1.11-1.525.713-.546 1.724-.546 1.509 0 2.354 1.26.845 1.242.845 3.646zm-2.735-3.845V123.8q0-.646-.232-.961-.232-.332-.68-.332-.447 0-.679.332-.232.315-.232.961v2.619H4.46v-2.089q0-.663-.216-.96-.232-.316-.646-.316-.431 0-.647.315-.215.298-.215.961v1.824zM11.602 114.456H6.514l2.337-.895L0 118.831v-4.126l6.05-3.564v2.404L0 109.948v-3.78l8.85 5.238-2.336-.861h5.088z"
            />
        </svg>
    )
}

