import { useToast } from '../../layout/components/toasts/useToast'
import { AxiosError } from 'axios'
import { RestErrorResponse } from '../network/rest'

export type UseErrorFunction<E> = (error: E) => void

export function useError(title?: string): UseErrorFunction<AxiosError<RestErrorResponse> | any> {
    const toast = useToast({ title /*timeout: 5_000*/ })

    function onError(error: AxiosError<RestErrorResponse> | any) {
        const dto = error.response?.data
        if (!error.response) {
            toast.error({ details: 'Unknown error' })
        } else if (dto?.status !== 'error' || !dto?.data) {
            toast.error({ details: 'Unknown error response' })
        } else {
            const data = Array.isArray(dto.data) ? dto.data[0] : dto.data
            toast.error({ title: title ?? data?.title, details: data?.detail })
        }
    }

    return onError
}
