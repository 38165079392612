import React from 'react';
import { Avatar } from './Avatar';
import { Menu } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { MenuPanel, MenuPanelItem, MenuPanelSeparator } from '../../../components/modal/MenuPanel';
import { Levels } from '../../../components/utils/levels';
import classNames from 'classnames';
import { CogIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@reactit/auth';
import { MeModel } from '../../../../core/models/apiMe';
import { useError } from '../../../../core/hooks/useError'

export function Navatar({ className }: { className?: string }) {

    const navigate = useNavigate()
    const { user, signOutAsync } = useAuth<MeModel>()
    const onSignOutError = useError('Failed to logout')

    function doLogout() {
        signOutAsync().catch(onSignOutError)
    }

    return (
        <div className={classNames('flex justify-center items-center', className)}>
            <Menu
                as='div'
                className='relative'
            >
                {({ open }) => (
                    <>
                        <Menu.Button
                            as='div'
                            className={classNames(
                                'rounded-full border-4 cursor-pointer transition',
                                'border-white/30 hover:border-white/40 active:border-white/50'
                            )}
                        >
                            <Avatar diameter={32} id={user?.id ?? 1} />
                        </Menu.Button>
                        {open && (
                            <Menu.Items as='div'>
                                <MenuPanel
                                    className={classNames(
                                        'absolute left-16 bottom-0 max-w-xs',
                                        Levels.MODAL,
                                    )}
                                >
                                    <div className='px-4 py-2 space-y-1'>
                                        <p className='truncate font-bold'>{user?.username}</p>
                                        <p className='truncate'>{user?.email}</p>
                                    </div>

                                    <MenuPanelSeparator />

                                    {/*<MenuPanelItem text='Settings' icon={CogIcon} onClick={() => navigate('/settings')} />*/}
                                    <MenuPanelItem text='Logout' icon={LockClosedIcon} onClick={doLogout} />
                                </MenuPanel>
                            </Menu.Items>
                        )}
                    </>
                )}
            </Menu>
        </div>
    )
}

