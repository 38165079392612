import React, { ReactNode, useMemo, useState } from 'react';
import { ToastContext, ToastContextType, ToastOptions, ToastState, ToastStateItem } from './useToast';


export interface ToastProviderProps {
    children?: ReactNode;
    options?: ToastOptions;
}

export function ToastProvider(props: ToastProviderProps) {
    const {
        children,
        options,
    } = props;

    const [state, setState] = useState<ToastState>({ toasts: [] });
    const ltu = useMemo<Map<string, ToastStateItem>>(() => new Map(), []);

    const context: ToastContextType = {
        state,
        setState,
        ltu,
        globalOptions: {
            dismissible: true,
            ...(options || {}),
        },
    }

    return (
        <ToastContext.Provider value={context}>
            {children}
        </ToastContext.Provider>
    )
}
