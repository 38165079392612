import React, { ClassAttributes, InputHTMLAttributes, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { NodeOrComponent, renderIconFromNodeOrComponent } from '../utils/render';


export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    accent?: boolean;
    disabled?: boolean;
    icon: NodeOrComponent;
    onClick?: MouseEventHandler;
    small?: boolean;
    invert?: boolean;
    inset?: boolean;
}

export function IconButton(props: IconButtonProps) {
    const { children, className, accent, disabled, icon, small, invert, inset, ...rest } = props;

    const btnClass = classNames(
        'rounded-full transition shrink-0 grow-0',
        {
            'p-1': small,
            'p-2': !small,

            '-m-1': inset && small,
            '-m-2': inset && !small,

            'opacity-50': disabled,
        },
        invert ? {

            'text-white': true,
            'hover:bg-white/10 active:bg-white/20': !disabled,

        } : {

            'text-black/30': !accent,
            'text-aindo-700': accent,

            'hover:bg-aindo-700/10 active:bg-aindo-700/20 active:text-aindo-700': !disabled,

            'hover:text-aindo-700/50': !accent && !disabled,
            'hover:text-aindo-700': accent && !disabled,

        },
        className
    )

    const button = (
        <button {...rest} disabled={!!disabled} className={btnClass}>
            {icon && renderIconFromNodeOrComponent(icon)}
            {children}
        </button>
    )

    if (inset) {
        // guarding negative margin from tailwind space-x-* and space-y-*
        return <div className='w-6 h-6'>{button}</div>
    } else {
        return button
    }
}
