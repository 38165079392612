import axios from 'axios';
import { MeModel, MeScopesModel, MeUpdateRequest } from '../models/apiMe';

export class MeApi {

    static async get(): Promise<MeModel> {
        const r = await axios.get(`/api/v1/me`)
        return r.data?.data;
    }

    static async update(data: MeUpdateRequest): Promise<MeModel> {
        const r = await axios.put(`/api/v1/me`, data);
        return r.data?.data;
    }

    static async scopes(): Promise<MeScopesModel> {
        const r = await axios.get(`/api/v1/me/scopes`);
        return r.data?.data;
    }

}
