import { Component } from 'react'
import { Skeleton } from '../../../components/common/Skeleton'
import { cyrb53 } from '../../../utils/cyrb53'

const jazzicon = require('jazzicon')

export interface AvatarProps {
    id: string | number
    diameter: number
    className?: string
}

export class Avatar extends Component<AvatarProps> {
    private lastId: string | number | undefined
    private applide: boolean = false
    private icon: HTMLDivElement | undefined
    private ref: HTMLDivElement | undefined

    constructor(props: AvatarProps) {
        super(props)
    }

    private generateIcon() {
        if (this.lastId === this.props.id && this.applide) {
            return false
        }

        this.icon?.remove()

        const seed = typeof this.props.id === 'string' ? cyrb53(this.props.id) : this.props.id
        this.icon = jazzicon(this.props.diameter, seed)
        this.lastId = this.props.id

        if (this.ref) this.ref.append(this.icon!)
    }

    componentDidMount() {
        this.generateIcon()
    }

    componentDidUpdate(prevProps: Readonly<AvatarProps>, prevState: Readonly<{}>, snapshot?: any) {
        this.generateIcon()
    }

    render() {
        return (
            <Skeleton.div className={this.props.className} skeletonClass='bg-gray-300 rounded-full'>
                <div
                    ref={el => (this.ref = el as HTMLDivElement)}
                    // The avatar is an inline block, with a strange line height
                    style={{ lineHeight: 0 }}
                />
            </Skeleton.div>
        )
    }
}
