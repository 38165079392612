import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { NodeOrComponent, renderIconFromNodeOrComponent } from '../utils/render';


export interface MenuPanelItemProps {
    icon?: NodeOrComponent;
    text: React.ReactNode;
    focus?: boolean;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    action?: string;
    key?: any;
}

export function MenuPanelItem(props: MenuPanelItemProps) {
    return (
        <div
            key={props.key}
            className={classNames(
                'p-2 pl-12 pr-4 rounded flex items-center select-none transition',
                {
                    'cursor-pointer hover:bg-aindo-50 active:bg-aindo-100': !props.disabled && !props.active,
                    'bg-aindo-50': !props.disabled && !props.active && props.focus,
                    'cursor-pointer bg-aindo-100 text-aindo-700': props.active,
                    'opacity-70': props.disabled,
                }
            )}
            // todo : prevent disabled menu items to close the menu
            onClick={props.onClick}
        >
            {props.icon && (
                <span className='-ml-9 mr-3'>
                    {renderIconFromNodeOrComponent(props.icon)}
                </span>
            )}
            <span className='truncate'>
                {props.text}
            </span>
        </div>
    )
}

export interface MenuSeparatorProps {
    separator?: true;
    key?: any;
}

export function MenuPanelSeparator(props?: MenuSeparatorProps) {
    return (
        <div key={props?.key} className='border-t-2 my-1 border-gray-200'/>
    )
}


export interface MenuPanelProps extends HTMLAttributes<HTMLDivElement> {
    items?: (MenuPanelItemProps | MenuSeparatorProps)[];
    onItemClick?: (aciton: string | undefined) => void;
}

export function MenuPanel(props: MenuPanelProps) {
    const { items, onItemClick, children, className, ...rest } = props;

    return (
        <div
            {...rest}
            className={classNames(
                'border-2 border-solid rounded-lg bg-white p-1 min-w-[14rem] overflow-auto shadow-lg',
                className
            )}
        >
            {items && items.map((f, ix) => {
                if ((f as MenuSeparatorProps).separator) {
                    return MenuPanelSeparator({key: ix, ...(f as MenuSeparatorProps)});
                } else {
                    return MenuPanelItem({key: ix, ...(f as MenuPanelItemProps)})
                }
            })}
            {children}
        </div>
    )
}

