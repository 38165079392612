import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';


interface LiveProviderContext {
    getSource: () => EventSource;
}

const LiveContext = createContext<LiveProviderContext | undefined>(undefined);

// There can only be few EventSources at once, so share it globally
let globalSource: EventSource | undefined = undefined;

export function LiveProvider(
    {
        children,
    }: {
        children: ReactNode,
    }
) {
    const context = useMemo(() => {
        return {
            getSource: () => (globalSource = (globalSource ?? new EventSource('/api/v1/live'))),
        }
    }, [])

    return (
        <LiveContext.Provider value={context} children={children} />
    )
}

export function useLive(callback: (event: any) => void) {
    const context = useContext(LiveContext);
    if (context == undefined) throw new Error(`useLive can only be used within a LiveProvider`);

    // Hack to call always the latest provided function in case it is not stable
    const fRef = useRef(callback);
    fRef.current = callback;

    useEffect(() => {
        function listener(event: any) {
            try {
                console.log(event.data);
                fRef.current?.(JSON.parse(event.data));
            } catch (error) {
                console.error('[live] Failed during event parsing', event, error);
            }
        }

        // Subscribe
        const source = context.getSource();
        source.addEventListener('live', listener);
        return () => source.removeEventListener('live', listener);
    }, [])
}
