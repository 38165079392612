import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import './progressbar.scss'

export function ProgressBar(
    {
        className,
        progress = 0.0,
        indeterminate = false,
        ...rest
    }: ComponentProps<'div'> & { progress?: number, indeterminate?: boolean }
) {
    // todo : find a better way to add colors
    return (
        <div
            className={classNames(
                'bg-aindo-600/30 rounded-full progressbar',
                className
            )}
            {...rest}
        >
            {indeterminate ? (
                <>
                    <div className='h-full bg-aindo-600 value-indt inc'/>
                    <div className='h-full bg-aindo-600 value-indt dec'/>
                </>
            ) : (
                <div
                    className='value bg-aindo-600'
                    style={{ width: `${progress * 100}%` }}
                />
            )}
        </div>
    )
}
