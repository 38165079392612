import React from 'react';
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '../common/IconButton';

export enum AlertType {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export interface AlertProps {
    type?: AlertType | 'info' | 'success' | 'warning' | 'error'
    title: string
    details?: React.ReactNode
    bullets?: React.ReactNode[]
    actions?: React.ReactNode
    className?: string
    invert?: boolean
    forceHorizontal?: boolean
    onClose?: () => void
}

// todo : move colors to a ltu
const stylesLtu = {
    [AlertType.Info]: {
        icon: InformationCircleIcon,
        styleIcon: 'text-aindo-500',
        style: 'bg-aindo-50 text-aindo-700',
        invertedStyleIcon: '',
        invertedStyle: 'bg-aindo-500 text-white',
    },
    [AlertType.Success]: {
        icon: CheckCircleIcon,
        styleIcon: 'text-green-500',
        style: 'bg-green-50 text-green-700',
        invertedStyleIcon: '',
        invertedStyle: 'bg-green-500 text-white',
    },
    [AlertType.Warning]: {
        icon: ExclamationTriangleIcon,
        styleIcon: 'text-amber-500',
        style: 'bg-amber-50 text-amber-700',
        invertedStyleIcon: '',
        invertedStyle: 'bg-amber-400 text-white',
    },
    [AlertType.Error]: {
        icon: XCircleIcon,
        styleIcon: 'text-rose-500',
        style: 'bg-rose-50 text-rose-700',
        invertedStyleIcon: '',
        invertedStyle: 'bg-rose-500 text-white',
    },
}


export function Alert(
    {
        type = AlertType.Info,
        title,
        details,
        bullets,
        actions,
        className,
        invert,
        forceHorizontal,
        onClose,
    }: AlertProps
) {
    const style = stylesLtu[type]
    const vertical = !forceHorizontal && !!(details || bullets);

    return (
        <div className={classNames(
            'flex p-4 rounded-md',
            vertical ? '' : 'items-center',
            invert ? style.invertedStyle : style.style,
            className
        )}>
            <div className={invert ? style.invertedStyleIcon : style.styleIcon}>
                <style.icon className='w-6 h-6 shrink-0 grow-0'/>
            </div>
            {vertical ? (
                // Strange paddings to avoid actions being truncated by overflow-hidden
                <div className='pl-3 -mb-4 pb-4 flex-1 flex flex-col space-y-2 overflow-hidden'>
                    <div className='font-bold truncate'>{title}</div>
                    {details && (<div>{details}</div>)}
                    {bullets && (
                        <ul className='list-disc pl-4'>
                            {bullets.map((el, ix) => (
                                <li key={ix}>{el}</li>
                            ))}
                        </ul>
                    )}
                    {actions && <div>{actions}</div>}
                </div>
            ) : (
                <>
                    <div className='ml-3 mr-6 flex-1 overflow-hidden'>
                        <div className='font-bold truncate'>{title}</div>
                        {details && (<div>{details}</div>)}
                    </div>
                    {actions}
                </>
            )}
            {onClose && (
                <div className='pl-3'>
                    <IconButton icon={XMarkIcon} inset onClick={onClose} />
                </div>
            )}
        </div>
    )
}

export function AlertButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    const { className, ...rest } = props
    return <button
        {...rest}
        className={classNames(
            'py-2 px-2 -my-2 -mx-2 font-bold rounded-md',
            'transition hover:bg-black/5 active:bg-black/10',
            className,
        )}
    />
}
