import React from 'react';
import { Alert, AlertButton, AlertType } from '../structural/Alert';
import { ToastType, useToast } from './useToast';
import { IconButton } from '../common/IconButton';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Levels } from '../utils/levels';


const LTU = {
    [ToastType.Info]: AlertType.Info,
    [ToastType.Success]: AlertType.Success,
    [ToastType.Warning]: AlertType.Warning,
    [ToastType.Error]: AlertType.Error,
}

export function ToastOutlet(props: {  }) {
    const { } = props;
    const toasts = useToast();

    return (
        <div className={`fixed ${Levels.OVER_MODAL} bottom-8 right-8`}>

            <div className='flex flex-col items-end space-y-2'>
                {toasts.toasts.slice(-4).map((t, ix) => {

                    const actions = (t.data.actions || [])
                        .map((a, jx) => (
                            <AlertButton
                                key={jx}
                                onClick={() => {
                                    const key = a.key || `a_${jx}`;
                                    a.onAction?.(key)
                                    t.data.onAction?.(key)
                                    if (a.dismissOnAction || t.data.dismissOnAction) toasts.dismiss(t);
                                }}
                            >
                                {a.label}
                            </AlertButton>
                        ));

                    if (t.data.dismissible) {
                        actions.push(
                            <IconButton
                                key={actions.length}
                                invert icon={XMarkIcon} className='-my-3 -mx-2'
                                onClick={() => toasts.dismiss(t)}
                            />
                        )
                    }

                    return (
                        <Alert
                            key={ix}
                            className='shadow-md max-w-lg'
                            invert forceHorizontal
                            type={LTU[t.data.type!]}
                            title={t.data.title!}
                            details={t.data.details}
                            actions={actions}
                        />
                    )

                })}
            </div>

        </div>
    )
}

