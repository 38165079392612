import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export enum ToastType {
    Info= 'i',
    Success = 's',
    Warning = 'w',
    Error = 'e',
}

export interface ToastDataAction {
    key?: string;
    label: string;
    onAction?: (key?: string) => any;
    dismissOnAction?: boolean;
    dismissible?: boolean;
}

export interface ToastData extends Pick<ToastDataAction, 'onAction' | 'dismissOnAction' | 'dismissible'> {
    // Toast
    type?: ToastType;
    title?: string;
    details?: string;
    // Behaviour
    actions?: ToastDataAction[];
    timeout?: number;
}

export type ToastOptions = Partial<ToastData>;

export interface ToastStateItem {
    id: string;
    data: ToastData;
    timeout: number | undefined; // timeout set, if any
}

export interface ToastState {
    toasts: ToastStateItem[];
}

export interface ToastContextType {
    state: ToastState;
    setState: Dispatch<SetStateAction<ToastState>>;
    ltu: Map<string, ToastStateItem>;
    globalOptions: ToastOptions;
}

export const ToastContext = createContext<ToastContextType | undefined>(undefined);

export interface UseToastResult {
    toasts: ToastStateItem[];

    // Show toasts
    submit: (data?: ToastData) => void;
    info: (data?: string | Omit<ToastData, 'type'>) => void;
    success: (data?: string | Omit<ToastData, 'type'>) => void;
    warning: (data?: string | Omit<ToastData, 'type'>) => void;
    error: (data?: string | Omit<ToastData, 'type'>) => void;

    // Dismiss a single toast, now or after some time
    dismiss: (toast: ToastStateItem | string, after?: number) => void;
    // Remove all toasts
    clear: () => void;
}

export function useToast(localOptions?: ToastOptions): UseToastResult {

    // Retrieve context and validate
    const ctx = useContext(ToastContext);
    if (!ctx) throw new Error('useToast can only be used inside a ToastProvider')
    const { state, setState, ltu, globalOptions } = ctx;

    function submit(data?: ToastData) {
        // Mix options
        data = {
            ...globalOptions,
            ...localOptions,
            ...data,
        }
        if (data.type === undefined) throw new Error('Toast type is required')
        if (data.title === undefined) throw new Error('Toast title is required')

        // todo: ask borut, why id is just a math.random instead of an uuid for example
        const id = `t_${Math.random()}`;
        const toast: ToastStateItem = { id, data: data as ToastData, timeout: undefined };

        if (data?.timeout !== undefined) {
            toast.timeout = setTimeout(() => dismiss(id), data.timeout) as any;
        }

        ltu.set(id, toast);
        setState({ toasts: Array.from(ltu.values()) })
    }

    function typed(type: ToastType) {
        return (data?: string | Omit<ToastData, 'type'>) => {
            submit({
                ...(typeof data === 'string' ? { title: data } : data || {}),
                type,
            })
        }
    }

    function dismiss(idf: ToastStateItem | string, after?: number) {
        const id = typeof idf === 'string' ? idf : idf.id;
        const toast = ltu.get(id);
        if (!toast) return;

        // Clear any timer
        clearTimeout(toast.timeout);

        if (after !== undefined) {
            toast.timeout = setTimeout(() => dismiss(id), after) as any;
        } else {
            ltu.delete(id);
            setState({ toasts: Array.from(ltu.values()) })
        }
    }

    function clear() {
        ltu.forEach(toast => clearTimeout(toast.timeout))
        setState({ toasts: [] })
    }

    return {
        toasts: state.toasts,
        submit,
        info: typed(ToastType.Info),
        success: typed(ToastType.Success),
        warning: typed(ToastType.Warning),
        error: typed(ToastType.Error),
        dismiss,
        clear,
    }
}
