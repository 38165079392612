import React, { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
    BellIcon,
    BoltIcon,
    BookOpenIcon,
    Cog6ToothIcon,
    CommandLineIcon,
    HomeModernIcon,
    PaintBrushIcon,
    PlayIcon,
    Square3Stack3DIcon,
} from '@heroicons/react/24/outline'
import { Navbar, NavbarAction } from './layout/components/navbar/Navbar'
import { ToastOutlet } from './layout/components/toasts/ToastOutlet'
import { ToastProvider } from './layout/components/toasts/ToastProvider'
import { LiveProvider } from './core/hooks/useLive'
import { Navatar } from './layout/pages/settings/components/Navatar'
import { AppAuthProvider, useAppAuthInterceptor } from './layout/components/auth/AppAuthProvider'
import { useIsAuthenticated } from '@reactit/auth'
import { Environ } from './env'
import { AxiosError } from 'axios'
import AindoByIcon from './assets/logos/aindo_by';

// Split off mock parts from main bundle
// https://github.com/facebook/create-react-app/discussions/9957
// https://blog.devgenius.io/implementing-react-router-v6-with-code-splitting-in-a-react-typescript-project-14d98e2cab79
const LazyRouteLogin = lazy(() => import('./layout/pages/login'))
const LazyRouteHome = lazy(() => import('./layout/pages/home'))
const LazyRouteBlocks = lazy(() => import('./layout/pages/blocks'))
const LazyRouteFlows = lazy(() => import('./layout/pages/flows'))
const LazyRouteSettings = lazy(() => import('./layout/pages/settings'))
// todo : exclude from build https://webpack.js.org/plugins/normal-module-replacement-plugin/
const LazyRouteMock = lazy(() => import('./layout/pages/mock'))

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // disable retry when error is a 4**, else default behaviour
            retry: (failureCount, error) => {
                const errorCode = (error as AxiosError).response?.status
                return errorCode ? !(errorCode >= 400 && errorCode < 500) : false
            },
        },
    },
})

function AppRoutes() {

    // Setup authentication header
    useAppAuthInterceptor()
    const isAuth = useIsAuthenticated();

    const layout = (
        <Navbar>
            <NavbarAction link='/' exact icon={HomeModernIcon} text='Home' />
            <NavbarAction link='/blocks' icon={Square3Stack3DIcon} text='Catalogs' />
            {Environ.isDev && <NavbarAction link='/flows' icon={BoltIcon} text='Quick Actions' />}
            <NavbarAction link='/flows' icon={PlayIcon} text='Flows' />
            {Environ.isDev && <NavbarAction link='/notebooks' icon={CommandLineIcon} text='Notebooks' />}

            <div className='flex-1' />

            <div className='flex justify-center'>
                <AindoByIcon className='w-5 py-8 fill-aindo-500' />
            </div>

            {Environ.isDev && (
                <NavbarAction link='/mock' exact icon={PaintBrushIcon} text='Mock' />
            )}

            {Environ.linkDocumentation && (
                <NavbarAction
                    rawLink
                    link={Environ.linkDocumentation}
                    target={'_blank'}
                    icon={BookOpenIcon}
                    text='Documentation'
                />
            )}

            {Environ.isDev && (
                <NavbarAction link='/notifications' icon={BellIcon} text='Notifications' isAttention />
            )}
            <NavbarAction link='/settings' icon={Cog6ToothIcon} text='Settings' />

            <Navatar className='pt-2' />

        </Navbar>
    )

    // (!) Be careful where <Suspense> components are placed to prevent flickery layout
    //     during loading
    return (
        <Routes>
            {/* Development routes */}
            {Environ.isDev && (
                <>
                    <Route element={layout}>
                        <Route path='/mock/*' element={<LazyRouteMock />} />
                    </Route>
                </>
            )}

            {/* Main routes */}
            {!isAuth ? (
                <>
                    <Route path='*' element={<LazyRouteLogin />} />
                </>
            ) : (
                <Route element={layout}>
                    <Route path='/blocks/*' element={<LazyRouteBlocks />} />
                    <Route path='/flows/*' element={<LazyRouteFlows />} />
                    <Route path='/settings/*' element={<LazyRouteSettings />} />
                    <Route path='/*' element={<LazyRouteHome />} />
                </Route>
            )}
        </Routes>
    )
}

export default function App() {
    return (
        <ToastProvider options={{ timeout: 5_000 }}>
            <QueryClientProvider client={queryClient}>
                <AppAuthProvider>
                    {/* todo : SSE needs changes inside node_modules/react-scripts to work*/}
                    <LiveProvider>
                        <BrowserRouter>
                            <ToastOutlet />
                            <AppRoutes />
                        </BrowserRouter>
                    </LiveProvider>
                </AppAuthProvider>
            </QueryClientProvider>
        </ToastProvider>
    )
}
