import axios from 'axios';
import {
    AuthRenewRequest,
    AuthRenewResponse,
    AuthRequest,
    AuthResponse,
    AuthSessionModel,
    AuthSessionQuery
} from '../models/apiAuth';

export const AUTH_URL = `/api/v1/auth`

export class AuthApi {

    static async login(data: AuthRequest): Promise<AuthResponse> {
        const r = await axios.post(`${AUTH_URL}/login`, data)
        return r.data?.data;
    }

    static async renew(data: AuthRenewRequest): Promise<AuthRenewResponse> {
        const r = await axios.post(`${AUTH_URL}/renew`, data)
        return r.data?.data;
    }

    static async logout(): Promise<void> {
        await axios.post(`${AUTH_URL}/logout`)
    }

    static async getSessions(query?: AuthSessionQuery): Promise<AuthSessionModel[]> {
        const r = await axios.get(`${AUTH_URL}/sessions`, { params: query })
        return r.data?.data;
    }

    static async revokeOtherSessions(): Promise<void> {
        await axios.delete(`${AUTH_URL}/sessions`)
    }

    static async getSession(id: string): Promise<AuthSessionModel> {
        const r = await axios.get(`${AUTH_URL}/sessions/${id}`)
        return r.data?.data;
    }

    static async revokeSession(id: string): Promise<void> {
        await axios.delete(`${AUTH_URL}/sessions/${id}`)
    }

}
